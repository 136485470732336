import { useState, useEffect } from 'react';
import Button from './Button';
import './categories.css';
import logojbp from './jeubougeplus.png';
//import logoa2s from './a2s.png';

function Opened(props) {
    return(
        <div id="menu-scr" className="menu-content fadein">
            <Button id="info" text="INFO" onClick={props.onClick} />
            <Button id="settings" text="PARAMETRES" onClick={props.onClick} />
            {/* <Button id="dice" text="DÉ" onClick={props.onClick} /> */}
            {/* <Button id="dice" text="JEU" onClick={props.onClick} /> */}
            <div className='signature-container' onClick={() => window.location = 'mailto:k0dard@proton.me'}><span>by k0dard&nbsp;</span><img src="k0dard.png" /></div>
        </div>
    );
}

function Menu(props) {
    const [debugInfo, setDebugInfo] = useState('');
    const opened = props.opened ? " menu_button_opened" : ""
    const updateDebugInfo = () => {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;

        setDebugInfo(`Viewport: ${viewportWidth}x${viewportHeight}, Screen: ${screenWidth}x${screenHeight}`);
    };
    // Effect hook to handle component mount and resize events
    useEffect(() => {
        updateDebugInfo(); // Update on mount
        window.addEventListener('resize', updateDebugInfo); // Update on resize

        // Cleanup function to remove the event listener
        return () => window.removeEventListener('resize', updateDebugInfo);
    }, []);
    return(
        <header>
            <div className="logos">
                <img src={logojbp} alt="JeuBouge+ logo"></img>
            </div>
            {/* <span style={{"z-index": "200"}}>{debugInfo}</span> */}
            <div className="menu">
                <span id="menu_icon" className={"menu_button" + opened} onClick={props.onClick}></span>
            </div>
            {props.opened && <Opened onClick={props.onClick} />}
        </header>
    );
}

export default Menu;