import {useState, useEffect} from 'react';
import Button from './Button';
import './categories.css';
import logoa2s from './a2s.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faInfoCircle, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
const activiteIntense = ["jamais", "1 à 2 fois par semaine", "3 fois par semaine ou plus"];
const activiteModere = ["jamais", "1 à 2 fois par semaine", "3 à 4 fois par semaine", "5 fois par semaine ou plus"];

function Activites({ intense, modere, handleChange }) {
    return (
        <div>
            <h2>Evaluez votre niveau d'activité physique :</h2>
            <div className="form-group">
                <label>Combien de fois par semaine faites-vous 20 minutes d’activité physique intense au point de transpirer ou de haleter ?<br />
                    (Exemple : jogging, port de charge lourde, aérobic ou cyclisme à allure rapide)
                </label>
                <select name="intense" className="form-control" value={intense} onChange={handleChange}>
                    <option value="-1">Selectionnez une option</option>
                    {activiteIntense.map((c, i) => <option value={i * 2} key={c}>{c}</option>)}
                </select>
            </div>
            <div className="form-group">
                <label>Combien de fois par semaine faites-vous 30 minutes d’activité physique modérée qui augmente votre fréquence cardiaque ou qui vous font respirer
                    plus fort que normalement ?<br /> (Exemple : marche rapide, porter des charges légères, faire du vélo à allure modérée)
                </label>
                <select name="modere" className="form-control" value={modere} onChange={handleChange}>
                    <option value="-1">Selectionnez une option</option>
                    {activiteModere.map((c, i) => <option value={i} key={c}>{c}</option>)}
                </select>
            </div>
        </div>
    )
}

function Score({ result }) {
    let message = "";
    switch (result) {
        case 0:
        case 1:
        case 2:
        case 3:
            message = "Il faut augmenter votre niveau d'activité physique.";
            break;
        case 4:
        case 5:
            message = "Bravo ! Vous êtes suffisament actif !";
            break;
        case 6:
        case 7:
            message = "Super ! Continuez comme ça !";
            break;
        default:
            break;
    }

    return (
        <div>
            <h2>{message}</h2>
        </div>
    )
}

function suggestLevel() {
    let score = parseInt(this.state.modere) + parseInt(this.state.intense);
    switch (score) {
        case 0:
        case 1:
        case 2:
            return 1;
        case 3:
        case 4:
        case 5:

            return 2;
        case 6:
        case 7:           
            return 3;
        default:
            break;
    }
}

function Info({ next }) {
    const [modere, setModere] = useState(-1)
    const [intense, setIntense] = useState(-1)
    
    useEffect(() => {
        // This code is equivalent to componentDidMount
        const root = document.getElementById("root");
        root.style.overflowY = "scroll";

        // The return function here is equivalent to componentWillUnmount
        return () => {
            root.style.overflowY = "hidden";
        };
    }, []);

    const handleChange = (e) => {
        if (e.target.name === 'modere') setModere(parseInt(e.target.value))
        if (e.target.name === 'intense') setIntense(parseInt(e.target.value))
    }

    return (
        <div className="info fadein">



            <img className='logo' src={logoa2s} alt="A2S logo"></img>

            <h1>Info</h1>
            <p>
                Ce jeu a été développé par Azur Sport Santé dans le but de faire passer de manière ludique et active
                les grands messages de santé publique et de permettre à la population d’augmenter leur niveau d’activité physique.
                Les niveaux de difficultés des exercices proposés sont basés sur les recommandations de l’Organisation Mondiale de Santé (OMS) 
                de 30 à 60 minutes (selon l’âge) d’activité physique à intensité modérée 5 fois par semaine.
            </p>
            <h2>Règles du Jeu</h2>
            <p>
                A chaque tour, il faut lancer le dé et avancer d’autant de cases que de points marqués. En fonction de la case sur laquelle vous arrivez, 
                choisissez la couleur pour passer à la question ou au défi.
                 A chaque bonne réponse ou défi réalisé, avancez d’une case.
            </p>
            <h2>Nombre de joueurs</h2>
            <p>
                Chaque joueur peut jouer sur son téléphone pour répondre aux questions et aux défis, mais le jeu offre également la possibilité à une seule
                personne de jouer le rôle d’animateur et de mener le jeu sur son téléphone pour plusieurs joueurs.
                <br /> <br />
                <b>Si un animateur mène le jeu</b> c’est lui que lance le dé, montre les exercices sur son écran et pose les questions. 
                Si les joueurs ont le même niveau d’activité physique, ils peuvent tous effectuer les exercices à chaque tour. Cependant, 
                seul le joueur dont c’est le tour doit répondre à la question ou réaliser le défi correspondant à la case sur laquelle il se trouve.
                <br /> <br />
                <span className='info-icon'><FontAwesomeIcon icon={faInfoCircle} />&nbsp;</span>
                Si vous jouez à plus de 8 joueurs, organisez l’activité en binômes (ex : 5 équipes de 2 joueurs). 
            </p>
            <h2>Difficulté</h2>
            <p>
                Le jeu est adapté à tous, de 3 ans à 65 ans et plus. Plusieurs niveaux de difficultés selon l’âge et le niveau d’activité physique 
                sont proposés pour adapter les exercices et les questions au plus grand nombre. Un niveau est dédié aux enfants de moins de 7 ans, 
                un à ceux entre 7 et 11 ans et pour les personnes de 12 ans et plus 3 niveaux de difficultés sont proposés :
            </p>
            
            <ul>
                <li>Débutant</li>
                <li>Intermédiaire</li>
                <li>Confirmé</li>

            </ul>
            <h2>Types de jeu</h2>
            <p>
            Vous pouvez choisir entre le jeu éducatif ou le jeu actif. Chaque type de jeu contient 5 catégories 
            correspondant aux différentes couleurs du plateau.
            </p>
            
            <table>
                <thead>
                    <tr>
                        <td></td>
                        <td>Jeu éducatif</td>
                        <td>Jeu Actif</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><div className="small_circle bg_color_1"></div></td>
                        <td>Activité physique</td>
                        <td>Cardio</td>
                    </tr>
                    <tr>
                        <td><div className="small_circle bg_color_2"></div></td>
                        <td>Alimentation</td>
                        <td>Souplesse</td>
                    </tr>
                    <tr>
                        <td><div className="small_circle bg_color_3"></div></td>
                        <td>Hygiène de vie</td>
                        <td>Renforcement musculaire</td>
                    </tr>
                    <tr>
                        <td><div className="small_circle bg_color_4"></div></td>
                        <td>Cases mystères</td>
                        <td>Equilibre</td>
                    </tr>
                    <tr>
                        <td><div className="small_circle bg_color_5"></div></td>
                        <td>Défis actifs</td>
                        <td>Coordination</td>
                    </tr>

                </tbody>
            </table>
            <p>
            Dans le jeu éducatif un exercice sera proposé systématiquement avant de répondre à la question ou de réaliser le défi.
            </p>
            <h2>Comment terminer ou gagner une partie de JeuBouge+</h2>
            <p>
                Pour terminer ou gagner une partie, il faut être le premier à arriver sur la dernière case, mais avec l’obligation
                d’arriver pile sur cette case. Dans le cas où le joueur fait un score au dé supérieur au nombre de cases le séparant de la victoire,
                il devra reculer d’autant de cases supplémentaires.
                <br /> <br />
                <span className='info-icon'><FontAwesomeIcon icon={faInfoCircle} />&nbsp;</span>
                Pour terminer le jeu plus rapidement (manque de temps), cette règle ne s’applique pas.
            </p>

            
            {/* {!localStorage.getItem('players') && <Button text="CONTINUEZ" css="settings_button" onClick={next} />} */}
            <Button text="JOUEZ" css="settings_button" onClick={next} />
        </div>
    );

}

export default Info;