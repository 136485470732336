import React from 'react';

class Timer extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        count: props.count
      };
    }

    componentDidUpdate() {
      if (this.props.start && !this.timerID) this.startTimer();
    }


    componentWillUnmount() {
      clearInterval(this.timerID); //in case user skips timer
    }
  
    startTimer() {
      this.timerID = setInterval(() => this.countdown(), 1000);
    }
  
    countdown() {
      let currentTime = this.state.count;
      currentTime--;
      if (currentTime === 3) document.getElementById("beep").play();
      if (currentTime === 0) {
        clearInterval(this.timerID);
        document.getElementsByClassName("button")[0].innerHTML = "TERMINÉ";
        //document.getElementsByClassName("button")[0].classList.remove("pulse");
        //document.getElementsByClassName("button")[0].classList.add("pulse");
      }
      this.setState(state => ({
        count: currentTime
      }));
    }
  
    render() {
      return (
        <div className="timer rollIn">
          <span className="counter">{this.state.count}</span>
          <span style={{fontSize: "2.2rem"}}>secondes</span>
          <audio src="timer-beep.wav" preload="auto" id="beep"></audio>
        </div>
      )
    }
}

export default Timer;