import React, { useState, useEffect } from 'react';
import Button from './Button';

function DiceOutcome(props) {
    const [ visible, changeVisibility ] = useState("hidden")
    useEffect(() => {
        if(props.fields) changeVisibility("show")
        else changeVisibility("hidden")
    }, [props.fields])

    return( 
        <div id="result" className={visible}>
            {/* <span>Avancez de {props.fields} case{props.fields === 1 ? "" : "s"}</span> */}
            <span>{props.player} avance de {props.fields} case{props.fields === 1 ? "" : "s"}</span>
            {visible === "show" && <Button text="OK" css="dice-button" onClick={props.onClick} />}
        </div>
    )
}

class Dice extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            outcome: ""
        };

        this.toTheGame = this.toTheGame.bind(this)
        this.diceRoll = this.diceRoll.bind(this)
    }

    componentDidMount() {
        window.scrollTo({top: 0})
    }

    componentWillUnmount() {
        clearTimeout(this.rollTimeout)
    }

    diceOutcome(val) {
        this.setState({outcome: val}); 
    }

    diceRoll() {
        const random = Math.round(Math.random() * 5 + 1);
        this.diceOutcome(0);
        this.rollTimeout = setTimeout(() => this.diceOutcome(random), 1000);
      }

    toTheGame() {
        this.setState({outcome: ""}); //reset the dice
        this.props.next();
    }

      render() {
        return(
            <div className="main-container fadein" onAnimationEnd={this.diceRoll}>
                    <div className="title">Cliquez pour lancer le dé</div>
                    <div className="dice-container">
                        <div className="dice dice_1" data-val={this.state.outcome} onClick={this.diceRoll}>
                            <div className="cube">
                                <div className="side side_1">
                                    <span className="dot dot_5"></span>
                                </div>
                                <div className="side side_2">
                                    <span className="dot dot_3"></span><span className="dot dot_7"></span>
                                </div>
                                <div className="side side_3">
                                    <span className="dot dot_3"></span><span className="dot dot_5"></span><span className="dot dot_7"></span>
                                </div>
                                <div className="side side_4">
                                    <span className="dot dot_1"></span><span className="dot dot_3"></span><span className="dot dot_7"></span><span className="dot dot_9"></span>
                                </div>
                                <div className="side side_5">
                                    <span className="dot dot_1"></span><span className="dot dot_3"></span><span className="dot dot_5"></span><span className="dot dot_7"></span><span className="dot dot_9"></span>
                                </div>
                                <div className="side side_6">
                                    <span className="dot dot_1"></span><span className="dot dot_4"></span><span className="dot dot_7"></span><span className="dot dot_3"></span><span className="dot dot_6"></span><span className="dot dot_9"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <DiceOutcome player={this.props.currentPlayer} fields={this.state.outcome} onClick={this.toTheGame} />
            </div>
        )
    }
}

export default Dice;