const serverUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : ''


export async function dbSaveVisitorData(data) {
    const id = localStorage.getItem('id')

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...data, id: id })
    };

    console.log(data)

    const res = await fetch(`${serverUrl}/v/save`, requestOptions)
    return res.json()

}

export function dbPing() {
    const id = localStorage.getItem('id')
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: id })
    };
    fetch(`${serverUrl}/v/ping`, requestOptions)
}

export function dbVisit(id) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(id)
    };
    fetch(`${serverUrl}/v/visit`, requestOptions)
}

export async function dbGetQuestion(category, level) {
    const result = await fetch(`${serverUrl}/random/question?cat=${category}&lvl=${level}`)

    return result.json()
}

export async function dbGetExercise(category, level) {
    const result = await fetch(`${serverUrl}/random/exercise?cat=${category}&lvl=${level}`)
    
    return result.json()
}

export async function dbGetWarmUp(timer, level) {
    const result = await fetch(`${serverUrl}/random/warm-up?timer=${timer}&lvl=${level}`)
 
    return result.json()
}