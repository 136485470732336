import React, { useEffect, useRef, useState } from 'react';
//import ReactDOM from 'react-dom';
import './categories.css';
import Button from './Button';
import Timer from './Timer';

import VideoPlayer from './VideoPlayer';
import Loader from "react-spinners/ClipLoader"

import { dbGetQuestion, dbGetExercise, dbGetWarmUp } from './dbCalls';

const isPlaying = (video) => !video.paused() && !video.ended() && video.currentTime() > 0 && video.readyState() > 2

function Question(props) {

  const category = props.category[3]; // Take 4th character of string i.e. cat3 => 3
  const level = props.level;

  console.log(`Level: ${level}`)

  const [ randomQ, setQuestion ] = useState({question: "", info: "", correct: "", answers: []});
  
  const backgroundColor = useRef(null)
  useEffect(() => {
    backgroundColor.current.className += ` bg${category}`
    dbGetQuestion(category, level).then(data => setQuestion(data))    

  }, [])

  const question = <span className="slideInDown question">{randomQ.question}</span>;
  const correct = randomQ.correct - 1;
  const info = <div className="info-text">{randomQ.info}</div>
  const buttons = [];

  for (let i = 0; i < randomQ.answers.length; i++) {
    if (props.answered) {
      if (props.choice === i/*randomQ.answers[i]*/ && i === correct) buttons.push(<Button text={randomQ.answers[i]} css={props.category + " correct"} key={"btn" + i} />);
      else if (props.choice === i/*randomQ.answers[i]*/) buttons.push(<Button text={randomQ.answers[i]} css={props.category + " incorrect"} key={"btn" + i} />);
      else if (i === correct) buttons.push(<Button text={randomQ.answers[i]} css={props.category + " correct corrected"} key={"btn" + i} />);
      else buttons.push(<Button text={randomQ.answers[i]} css={props.category} key={"btn" + i} />);
    } else buttons.push(<Button id={"btn" + i} text={randomQ.answers[i]} css={props.category} onClick={props.answerQ} key={"btn" + i} />);
  }

  return (
    <div ref={backgroundColor} className='content fadein' style={{ height: `${window.innerHeight}px`}}>
      <div className={"text fadein"}>
        {question}
        <div className="buttons">{buttons}</div>
        {props.answered && info}
        {props.answered && <Button id="continue" text="CONTINUEZ" css={props.category + " pulse"} onClick={props.onClick} />}
      </div>
    </div>

  );
}

function Exercise(props) {
  
  const category = props.category[3]; // Take 4th character of string i.e. cat3 => 3
  const level = props.level;
  const playerRef = React.useRef(null);
  const [ randomX, setExercise ] = useState({instructions: "", timer: "", count: "", video: null})
  const [ loading, setLoading ] = useState(true)
  const [ playing, startVideo ] = useState(false)

  const handleClick = (e) => {
    const btn = e.currentTarget.id  // .innerText
    if (btn === "next" /*"PASSEZ" || btn ==="TERMINÉ"*/) {
      setLoading(true)
    }
    if (randomX.video) {
      if (playerRef.current &&  !isPlaying(playerRef.current)) playerRef.current.play()
    }
    
    props.onClick(e)
  }

  const backgroundColor = useRef(null)
  useEffect(() => {
    setTimeout(() => {
      backgroundColor.current.className += ` bg${category}`
    }, 500);
  },[])

  useEffect(() => {
    // Training mode
    if (props.trainingMode === "1") {
      dbGetExercise(category, level).then(data => {
        setExercise(data)
      })
    }
    // 2nd exercise, 5th category
    else if (props.isQualified) {
      let random = Math.floor(Math.random() * 5) + 1

      dbGetExercise(random, level).then(data => {
        setExercise(data)
        startVideo(false)
        if (!data.video && playerRef.current) playerRef.current.dispose()
        else if (playerRef.current) playerRef.current.src(data.video)
      })
    }
    //Warm-up mode
    else {
      dbGetWarmUp(props.timer, level).then(data => setExercise(data))
    }
    /*return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };*/
    
  }, [props.isQualified])

  let exercise = <span className="rotateInDownRight exTitle">{randomX.instructions}</span>;
  let reps = randomX.count;
  let s = reps > 1 ? "s" : "";
  let series =  <div className='timer'>
                  <span className="counter rollIn">{reps}</span><br/>
                  <span className="rollIn">{"répétition" + s}</span>
                </div>;
  /*let video = <video autoPlay muted loop id="myVideo" >
                <source src={randomX.video} type="video/mp4"/>
              </video>;*/
  let buttonText = props.timeRun ? "PASSEZ" : "START"
  //in case autoplay is not working, allow user to play video manually
  let autoplay = playing ? "TERMINÉ" : 
  randomX.video ? "START" : "TERMINÉ"
  // attach clickAction id to button so the flow can go independently of language (in case google translate is used)
  let clickAction = buttonText === "START" ? "start" : "next"
  let button = <Button id={randomX.timer ? clickAction : "next"} text={randomX.timer ? buttonText : autoplay} css={props.category + " pulse"} onClick={handleClick} />;  

  const videoJsOptions = {
    autoplay: true,
    controls: false,
    loop: true,
    muted: true,
    playsinline: true,
    responsive: true,
    sources: [{
      src: randomX.video,
      type: 'video/mp4'
    }],
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    /*
    player.on('waiting', () => {
      player.log('player is waiting');
    });

    player.on('dispose', () => {
      setLoading(true);
    });

    player.on('ready', () => {
      player.log('ready')
    })
    */
    player.on('loadeddata', () => {
      setLoading(false);
    })
    
    
    player.on('playing', () => {
      //setLoading(false);
      startVideo(true)
    })
    
  };
  return (
    <div ref={backgroundColor} className={"content"}>
      {randomX.video && <VideoPlayer options={videoJsOptions} onReady={handlePlayerReady} />/*video*/}
      {loading && randomX.video && <div className='loader'>
        <Loader loading={loading} size={50} color="#fff" />
      </div>}
      {(!loading || !randomX.video) && <div className={"text fadein"}>
        {exercise}
        {!randomX.timer && series}
        {randomX.timer === 1 && <Timer count={reps} start={props.timeRun} color={props.category} />}
        {button}
      </div>}
    </div>
  );
}

function Colors(props) {
  return (
    <div className="fadein">
      <div className="container">
        <div id="cat1" onClick={props.onClick}><div className="circle"></div></div>
        <div id="cat2" onClick={props.onClick}><div className="circle"></div></div>
        <div id="cat3" onClick={props.onClick}><div className="circle"></div></div>
        <div id="cat4" onClick={props.onClick}><div className="circle"></div></div>
        <div id="cat5" onClick={props.onClick}><div className="circle"></div></div> 
      </div>
      <div className="game-text">
        <span>Choisissez la couleur</span>
      </div>
    </div>
    
  );
}

const ColorSelector = ({ timer, next, currentPlayer, nextPlayer, mode }) => {
  const [category, setCategory] = useState("")
  const [level, setLevel] = useState(currentPlayer.level || "2");
  const [isQualified, setIsQualified] = useState(false);
  const [answered, setAnswered] = useState(false);
  const [choice, setChoice] = useState(null);
  const [timeRun, setTimeRun] = useState(false);

  // Update the component state when the currentPlayer changes.
  useEffect(() => {
    setLevel(currentPlayer.level || "2");
    setAnswered(false);
    setIsQualified(false);
    setChoice(null);
    // Reset timeRun based on the logic you have. For simplicity, resetting it to false here.
    setTimeRun(false);
  }, [currentPlayer]);

  const selectCategory = (e) => {
    if (category === "") {
      const clicked = e.currentTarget.id; // Get category

      // This approach to directly manipulating DOM is not ideal in React.
      // Consider using state to control classNames or styles instead.
      e.target.className += " expand";
      e.currentTarget.style.zIndex = "10";
      setCategory(clicked);
    }
  };

  const answerQ = (e) => {
    if (!answered) {
      const choice = parseInt(e.target.id[3]);
      setAnswered(true);
      setChoice(choice);
    }
  };

  const handleClick = (e) => {
    const { id } = e.currentTarget;

    if (id === "start") {
      setTimeRun(true);
    } else if (id === "next") {
      if (mode === "1" || (isQualified && category === "cat5")) {
        setCategory("");
        next();
        nextPlayer(); // Mark the end of the player's turn
      } else {
        setIsQualified(true);
        setTimeRun(false);
        setAnswered(false);
      }
    } else if (id === "continue") {
      setCategory("");
      setIsQualified(false);
      setAnswered(false);
      next();
      nextPlayer(); // Mark the end of the player's turn
    }
  };

  return (
            <>
              {/*this.state.category === "" &&*/ <Colors onClick={selectCategory} />}
              {category !== "" && (!isQualified || category === "cat5") && <Exercise
                                                                      trainingMode={mode} 
                                                                      timer={Math.floor(Math.random() * 2)} // replaced timer
                                                                      timeRun={timeRun}
                                                                      category={category}
                                                                      level={level}
                                                                      onClick={handleClick}
                                                                      isQualified={isQualified}
                                                                    />
              }
              {category !== "" && category !== "cat5" && isQualified && <Question 
                                                                      level={level}
                                                                      category={category}
                                                                      answered={answered}
                                                                      choice={choice}
                                                                      answerQ={answerQ}
                                                                      timeRun={timeRun} //dodato za kategoriju 5 defi actif 
                                                                      onClick={handleClick} 
                                                                    />
              }
            </>
          );
};

// class ColorSelector extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       category: "",
//       isQualified: false,
//       answered: false,
//       choice: null,
//       correct: false,
//       level:  localStorage.getItem('level') || "1",
//       trainingMode: localStorage.getItem('mode') || "0",
//       timeRun: false
//     };

//     this.handleClick = this.handleClick.bind(this);
//     this.selectCategory = this.selectCategory.bind(this)
//     this.answerQ = this.answerQ.bind(this)
//   }

//   selectCategory(e) {
//     //e.stopPropagation()
//     if (this.state.category === "") {
//       let clicked = e.currentTarget.id; //get category

//       e.target.className += " expand";
//       e.currentTarget.style.zIndex = "10";
//       this.setState(state => ({
//         category: clicked
//       }));
      
//     }
//   }

//   answerQ(e) {
//     if (!this.state.answered) {
//       const choice = parseInt(e.target.id[3])
//       this.setState(state => ({
//         answered: true,
//         choice: choice//e.target.innerText
//       }));
//     }
//   }

//   handleClick(e) {
    
//       if (/*e.target.innerText === "START"*/e.currentTarget.id === "start") {
//         console.log("ID: " + e.currentTarget.id)
//         //if (!this.props.timer) return //don't change the state if there is no timer, in case of autoplay not working
//         this.setState(state => ({
//           timeRun: true
//         }));
//       }
//       else if (e.currentTarget.id === "next"/*e.target.innerText === "PASSEZ" || e.target.innerText === "TERMINÉ"*/) {
//         console.log("ID: " + e.currentTarget.id)
//         if (this.state.trainingMode === "1" || (this.state.isQualified && this.state.category === "cat5")) { //|| dodat deo za defi actif cat 5

//           this.setState(state => ({
//             category: ""
//           }));
//           this.props.next();
          
//         } else {
//           this.setState(state => ({
//             isQualified: true,
//             timeRun: false,
//             answered: false
//           }));
//         }
//       }
//       else if (e.currentTarget.id === "continue" /*e.target.innerText === "CONTINUEZ"*/) {
//         console.log("ID: " + e.currentTarget.id)
//         this.setState(state => ({
//           category: "",
//           isQualified: false,
//           answered: false
//         }));
//         this.props.next();
//       }

//   }

//   render() {
//       return (
//         <>
//           {/*this.state.category === "" &&*/ <Colors onClick={this.selectCategory} />}
//           {this.state.category !== "" && (!this.state.isQualified || this.state.category === "cat5") && <Exercise
//                                                                   trainingMode={this.state.trainingMode} 
//                                                                   timer={this.props.timer}
//                                                                   timeRun={this.state.timeRun}
//                                                                   category={this.state.category}
//                                                                   level={this.state.level}
//                                                                   onClick={this.handleClick}
//                                                                   isQualified={this.state.isQualified}
//                                                                 />
//           }
//           {this.state.category !== "" && this.state.category !== "cat5" && this.state.isQualified && <Question 
//                                                                   level={this.state.level}
//                                                                   category={this.state.category}
//                                                                   answered={this.state.answered}
//                                                                   choice={this.state.choice}
//                                                                   answerQ={this.answerQ}
//                                                                   timeRun={this.state.timeRun} //dodato za kategoriju 5 defi actif 
//                                                                   onClick={this.handleClick} 
//                                                                 />
//           }
//         </>
//       );
//   }
  
// }

export default ColorSelector;