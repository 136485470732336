import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faInfoCircle, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import Button from './Button';
import './categories.css';

function Mode({ mode, onChange }) {
  return (
      <div style={{ width: "100%"}}>
        <h2>Choisissez le mode:</h2>
          <div className="form-group">
              <label>
                  <input
                      name="mode"
                      value="0"
                      type="radio"
                      className="input-radio"
                      checked={mode === "0"}
                      onChange={onChange}
                  /> JeuBouge+ éducatif{/*&nbsp;<div className='info-icon'><FontAwesomeIcon icon={faInfoCircle} /></div>*/}
              </label>
              <label>
                  <input
                      name="mode"
                      value="1"
                      type="radio"
                      className="input-radio"
                      checked={mode === "1"}
                      onChange={onChange}
                  /> JeuBouge+ actif{/*&nbsp;<div className='info-icon'><FontAwesomeIcon icon={faInfoCircle} /></div>*/}  
              </label>
          </div>
      </div>
    
  );
}

function Gender({ gender, onChange }) {
  return (
    <select name="gender" className="form-control" value={gender} onChange={onChange}>
      <option value="-1">?</option>
      <option value="1">F</option>
      <option value="2">H</option>
    </select>
  );
}

function Difficulty({ level, onChange }) {
  return (
    <select name="level" className="form-control" value={level} onChange={onChange}>
      <option value="2">Débutant</option>
      <option value="3">Intermédiaire</option>
      <option value="4">Confirmé</option>
      {/* <option value="5">Activité Physique Adaptée</option> */}
    </select>
  );
}

function Age({ age, onChange }) {
  return (
    <input
      name="age"
      type="number"
      className="form-control"
      value={age >= 1 ? age : ""}
      min="3"
      max="130"
      required
      onChange={onChange}
    />
  );
}

function Name({ name, onChange }) {
  return (
    <input
      name="name"
      type="text"
      className="form-control"
      value={name}
      onChange={onChange}
    />
  );
}

function Player({ player, onChange }) {
    const updatePlayer = (e) => onChange(e, player.id)
    // Return an array of divs for grid positioning
    return [
        <Name name={player.name} onChange={updatePlayer} />,
        // Conditionally render difficulty or an empty div if age < 12
        player.age < 12 ? <div key={`difficulty-${player.id}`}></div> : <Difficulty id={player.id} level={player.level} onChange={updatePlayer} />,
        <Age age={player.age} id={player.id} onChange={updatePlayer} />,
        <Gender gender={player.gender} id={player.id} onChange={updatePlayer} />,
    ];
  }

  function Multiplayer({ players, addPlayer, removePlayer, updatePlayer }) {
    const difficulty = players.some(player => player.age >= 12)
    return (
      <>
        <div className="multiplayer">
          <h2>Joueurs:</h2>
          <div className='multiplayer_container'>
            <div className="multiplayer_circle">
              <div className="multiplayer_number">{players.length}</div>
            </div>
            <div className='multiplayer_icons'>
              <div className="button-icon" onClick={addPlayer}>
                <FontAwesomeIcon icon={faPlus} />
              </div>
              <div className="button-icon" onClick={removePlayer}>
                <FontAwesomeIcon icon={faMinus} />
              </div>
            </div>

          </div>

        </div>
        <div className="players-grid">
          <div>Prénom</div>
          {difficulty ? <div>Difficulté</div> : <div></div>}
          <div>Age</div>
          <div>Genre</div>
          {players.flatMap((player, index) => (
            <Player key={'player' + index} player={player} onChange={updatePlayer} />
          ))}
        </div>
      </>
    );
  }

function Settings({ saveVisitorData, players, setPlayers, mode, setMode, next }) {
  
  const addPlayer = () => {
    if (players.length === 8) return;
    setPlayers(currentPlayers => [
      ...currentPlayers,
      { id: Date.now(), name: `Joueur ${currentPlayers.length + 1}`, age: '', gender: '-1', level: '2' },
    ]);
  };

  const removePlayer = () => {
    setPlayers(currentPlayers => {
      if (currentPlayers.length > 1) {
        return currentPlayers.slice(0, -1);
      }
      return currentPlayers;
    });
  };

  const handleChange = (e, playerId) => {
    const { name, value } = e.target;
    // change level depending on player's age
    if (name === 'age') {
      setPlayers(currentPlayers => 
        currentPlayers.map(player => {
          if (player.id === playerId) {
            let level = player.level
            if (value >= 12 && parseInt(level) < 2) level = "2"
            if (value < 12) level = "1"
            if (value < 7) level = "0"
            return { ...player, age: value, level: level }
          }
          return player
        })
      );
    }
    else setPlayers(currentPlayers =>
      currentPlayers.map(player => player.id === playerId ? { ...player, [name]: value } : player)
    );
  };

  const changeMode = (e) => {
    setMode(e.target.value);
  };


  const missingPlayersAge = players.some(player => player.age === '')

  return (
      <div className="settings fadein">
          <h1>Parametres</h1>
          <Multiplayer
              players={players}
              addPlayer={addPlayer}
              removePlayer={removePlayer}
              updatePlayer={handleChange}
          />
          <hr />
          <Mode mode={mode} onChange={changeMode} />
          {!missingPlayersAge && <Button text="CONTINUEZ" css="settings_button fadein" onClick={next} />}
      </div>
  );
}

export default Settings;