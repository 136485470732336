
import { useState, useEffect } from 'react';
import Menu from './Menu';
import Settings from './Settings';
import Dice from './Dice';
import Info from './Info';
import ColorSelector from './ColorSelector';
import { dbPing, dbVisit, dbSaveVisitorData } from './dbCalls';
import screenfull from 'screenfull';
import './categories.css';

// tracking visitors

// var expectedNextTick, timer

// function ping() {

//   dbPing() // ping db
//   expectedNextTick += 60000 // expected next ping in one minute
//   timer = setTimeout(ping, expectedNextTick - Date.now()) // next ping in expected time - now

// }

const GameFlow = () => {
  const [menu, setMenu] = useState(false);
  // const [current, setCurrent] = useState(() => {
  //   // decide what screen to display upon user visit
  //   const savedPlayers = localStorage.getItem('players');
    
  //   const playersMissingAge = savedPlayers ? JSON.parse(savedPlayers).some(player => player.age === "") : true
  //   return savedPlayers ?
  //     playersMissingAge ? "settings" : "dice"
  //     : "info"
  // });
  // const [players, setPlayers] = useState(() => {
  //   // Try to load players from localStorage
  //   const savedPlayers = localStorage.getItem('players');
  //   return savedPlayers ? JSON.parse(savedPlayers) : [
  //     { id: Date.now(), name: 'Joueur 1', age: '', gender: '-1', level: '2' }
  //   ];
  // });
  // const [mode, setMode] = useState(() => {
  //   // Try to load mode from localStorage
  //   const savedMode = localStorage.getItem('mode'); // 0 educatif 1 actif
  //   return savedMode !== null ? savedMode : "0"; // "0" as default mode
  // });
  const [current, setCurrent] = useState("info")
  const [players, setPlayers] = useState([{ id: Date.now(), name: 'Joueur 1', age: '', gender: '-1', level: '2' }])
  const [mode, setMode] = useState("0")
  const [currentPlayer, setCurrentPlayer] = useState(0) // player turn
  const [timer, setTimer] = useState(0)
  // switch timer on and off every time dice is rolling
  // CURRENTLY TIMER IS SET RANDOMNLY INSIDE ColorSelector COMPONENT!!
  useEffect(() => {
    if (current === "game") {
      setTimer(prevTimer => prevTimer === 0 ? 1 : 0)
    }
  }, [current])

  useEffect(() => {
    const savedPlayers = localStorage.getItem('players');
    if (savedPlayers) {
      const players = JSON.parse(savedPlayers);
      const playersMissingAge = players.some(player => player.age === "");
      setCurrent(playersMissingAge ? "settings" : "dice");
      setPlayers(JSON.parse(savedPlayers))
    }
    const savedMode = localStorage.getItem('mode'); // 0 educatif 1 actif
    if (savedMode) setMode(savedMode)
    
  }, [])

  // useEffect(() => {
  //   document.title = "JeuBouge+";
    // const id = localStorage.getItem('id');
    // if (id) {
    //   console.log('old visitor: ' + id);
    //   dbVisit({ id });
    // } else {
    //   console.log('new visitor');
    //   setCurrent("info");
    // }
    // Additional setup or teardown logic can be placed here
  // }, []);

  useEffect(() => {
    localStorage.setItem('players', JSON.stringify(players));
    localStorage.setItem('mode', mode);
    if (currentPlayer > players.length - 1) setCurrentPlayer(0)
  }, [players, mode]);

  const enterFullScreen = () => {
    if (screenfull.isEnabled && !screenfull.isFullscreen) {
      screenfull.request();
    }
  };

  const handleClick = (e) => {
    enterFullScreen();
    const targetId = e.target.id;
    switch (targetId) {
      case "menu_icon":
        setMenu(prevMenu => !prevMenu);
        break;
      default:
        setCurrent(targetId);
        if (menu) setMenu(false)
        break;
    }
  };

  const nextPlayer = () => {
    const numberOfPlayers = players.length
    if (numberOfPlayers === 1) return
    const current = currentPlayer === numberOfPlayers - 1 ? 0 : currentPlayer + 1
    setCurrentPlayer(current)
  }

  //   handleClick(e) {
//     this.enterFullScreen()
//     if (e.target.id === "menu_icon") {
//       const showMenu = this.state.menu
//       this.setState({ menu: !showMenu })
//     } else {
//       let clicked = e.currentTarget.id//.innerHTML.toLowerCase();
//       this.changeState(clicked);
//     }
//   }

  const saveVisitorData = (data) => {
    dbSaveVisitorData(data).then(res => {
      console.log(res);
      localStorage.setItem('id', res.id);
      // Logic to start visit could be adapted here
    });
  };

  return (
    <div className="full-height">
      <Menu opened={menu} onClick={handleClick} />
      {current === "settings" && <Settings next={() => setCurrent("dice")} saveVisitorData={saveVisitorData} players={players} setPlayers={setPlayers} mode={mode} setMode={setMode} />}
      {current === "dice" && <Dice currentPlayer={players[currentPlayer].name} next={() => setCurrent("game")} />}
      {current === "game" && <ColorSelector timer={timer} next={() => setCurrent("dice")} currentPlayer={players[currentPlayer]} nextPlayer={nextPlayer} mode={mode} />}
      {current === "info" && <Info next={() => setCurrent(players[0].age ? "dice" : "settings")} />}
    </div>
  );
};

export default GameFlow;

// import React from 'react';
// import ColorSelector from './ColorSelector';
// import Menu from './Menu';
// import Settings from './Settings';
// import Dice from './Dice';
// import Info from './Info';
// import './categories.css';
// import { dbPing, dbSaveVisitorData, dbVisit } from './dbCalls';
// import screenfull from 'screenfull';

// var expectedNextTick, timer

// function ping() {

//   dbPing() // ping db
//   expectedNextTick += 60000 // expected next ping in one minute
//   timer = setTimeout(ping, expectedNextTick - Date.now()) // next ping in expected time - now

// }

// class GameFlow extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       menu: false,
//       timer: 0,
//       current: "info"
//     };

//     this.enterFullScreen = this.enterFullScreen.bind(this)
//     this.handleClick = this.handleClick.bind(this);
//     this.changeState = this.changeState.bind(this);
//     this.saveVisitorData = this.saveVisitorData.bind(this);
//   }

//   componentDidMount() {

//     document.title = "JeuBouge+"; // da li mi treba ovo ?

//     // check if it's a new visitor

//     const id = localStorage.getItem('id')

//     if (id) {
//       console.log('old visitor: ' + id)

//       dbVisit({ id: id }) // update number of visits for current visitor

//       // start measuring the time visitor spent playing
//       // every minute db is updated

//       this.startVisit()

//     }
//     else {
//       console.log('new visitor')
//       this.setState({ current: "info" })
//     }

//     // const adjustFullHeightElements = () => {
//     //     const fullHeightElements = document.querySelectorAll('.full-height');
//     //     fullHeightElements.forEach(element => {
//     //         element.style.paddingBottom = `${window.screen.height - window.innerHeight}px`;
//     //     });

//     // };

//     // // Adjust height initially
//     // adjustFullHeightElements();   

//     // // Adjust height on window resize
//     // window.addEventListener('resize', adjustFullHeightElements);

//     // Remember to remove the event listener when the component unmounts to prevent memory leaks
//     // this.adjustFullHeightElements = adjustFullHeightElements; // Store the function for later removal

//   }

//   componentWillUnmount() {
//     clearInterval(timer)
//     // Remove the resize event listener
//     // window.removeEventListener('resize', this.adjustFullHeightElements);
//   }

//   enterFullScreen() {
//     if (screenfull.isEnabled && !screenfull.isFullscreen) {
//       screenfull.request();
//     }
//   }

//   startVisit() {
//     if (timer) clearTimeout(timer) // clear timer if it's running
//     expectedNextTick = Date.now() + 60000 // expected time for next call
//     timer = setTimeout(ping, 60000); // call ping in 60 seconds
//     this.setState({ current: "dice" })
//   }



//   handleClick(e) {
//     this.enterFullScreen()
//     if (e.target.id === "menu_icon") {
//       const showMenu = this.state.menu
//       this.setState({ menu: !showMenu })
//     } else {
//       let clicked = e.currentTarget.id//.innerHTML.toLowerCase();
//       this.changeState(clicked);
//     }
//   }

//   changeState(state) {
//     this.enterFullScreen()
//     switch (state) {

//       case "settings":
//         this.setState({
//           menu: false,
//           current: "settings"
//         });
//         break;

//       case "dice":
//         this.setState({
//           menu: false,
//           current: "dice"
//         });
//         break;

//       case "game":
//         const timer = this.state.timer === 0 ? 1 : 0;
//         this.setState({
//           menu: false,
//           timer: timer,
//           current: "game"
//         })
//         break;

//       case "info":
//         this.setState({
//           menu: false,
//           current: "info"
//         })
//         break;

//       default:
//         break;
//     }
//   }

//   saveVisitorData(data) {

//     dbSaveVisitorData(data).then(res => {
//       console.log(res)
//       localStorage.setItem('id', res.id)
//       this.startVisit()
//     })

//   }

//   render() {

//     return (
//       <div className="full-height">
//         {<Menu opened={this.state.menu} onClick={this.handleClick} />}

//         {this.state.current === "settings" && <Settings saveVisitorData={this.saveVisitorData} />}

//         {this.state.current === "dice" && <Dice next={() => this.changeState("game")} />}

//         {this.state.current === "game" && <ColorSelector timer={this.state.timer} next={() => this.changeState("dice")} />}

//         {this.state.current === "info" && <Info next={() => this.changeState("settings")} />}

//       </div>

//     );

//   }
// }



// export default GameFlow;